<template>
  <div>
    <vx-card
      :title="`Projets en cours (${
        this.allProjects.list.filter(
          (el) =>
            el.status == 'notTerminate' ||
            el.status == 'projetNotOk' ||
            el.status == 'quote'
        ).length 
      } devis / ${
        this.allProjects.list.filter((el) => el.status == 'none').length
      } souscriptions)`"
      class="mb-4"
    >
      <div slot="no-body">
        <div class="p-8">
          <div class="pt-2 pb-4 aab-vie-filter">
            <vs-collapse>
              <vs-collapse-item class="aab-vie-collapse-item">
                <div slot="header">
                  <div class="aab-vie-collapse-filter">Filtrage</div>
                </div>
                <div class="p-3">
                  <vs-row vs-w="12" vs-align="center">
                    <vs-col
                      class="pr-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <span class="pl-1 pr-1">Du</span>
                      <div
                        class="inputx w-full flex justify-around"
                        style="align-items: center"
                      >
                        <vs-input
                          v-model="startDate"
                          type="date"
                          class="
                            aab-vie-disable-input-date
                            inputx
                            w-full
                            mb-2
                            mt-2
                          "
                        />
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <span class="pl-1 pr-1">Au </span>
                      <div
                        class="inputx w-full flex justify-around"
                        style="align-items: center"
                      >
                        <vs-input
                          type="date"
                          v-model="endDate"
                          :min="startDate"
                          class="
                            aab-vie-disable-input-date
                            inputx
                            w-full
                            mb-2
                            mt-2
                          "
                        />
                      </div>
                    </vs-col>
                  </vs-row>
                  <vs-row vs-w="12" vs-align="center">
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <span class="pl-2">Avis de souscription</span>
                        <multiselect
                          v-model="notice"
                          :options="noticeList"
                          :close-on-select="true"
                          placeholder="--Choisir un avis de souscription--"
                          label="label"
                          track-by="label"
                          :selectLabel="global.selectLabel"
                          :selectedLabel="global.selectedLabel"
                          :deselectLabel="global.deselectLabel"
                        >
                        </multiselect>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <span class="pl-2">Etat</span>
                        <multiselect
                          v-model="projectStatus"
                          :options="allStatus"
                          :close-on-select="true"
                          placeholder="--Etat--"
                          label="label"
                          track-by="label"
                          :selectLabel="global.selectLabel"
                          :selectedLabel="global.selectedLabel"
                          :deselectLabel="global.deselectLabel"
                        >
                        </multiselect>
                      </div>
                    </vs-col>
                  </vs-row>
                  <vs-row vs-w="12" vs-align="center">
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <span class="pl-2">Produit</span>
                        <multiselect
                          v-model="projetProduct"
                          :options="allProduct"
                          :close-on-select="true"
                          placeholder="--Produit--"
                          label="label"
                          track-by="label"
                          :selectLabel="global.selectLabel"
                          :selectedLabel="global.selectedLabel"
                          :deselectLabel="global.deselectLabel"
                        >
                        </multiselect>
                      </div>
                    </vs-col>
                  </vs-row>
                  <vs-row vs-w="12" vs-align="center" vs-justify="center">
                    <vs-col
                      class="pt-4 pb-2"
                      vs-type="flex"
                      vs-align="center"
                      vs-justify="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <vs-button @click="resetFilter">
                        Réinitialiser
                      </vs-button>
                    </vs-col>
                  </vs-row>
                </div>
              </vs-collapse-item>
            </vs-collapse>
          </div>
          <div>
            <vs-prompt
              title="Exportation"
              class="export-options"
              @cancel="clearFields"
              @accept="exportToData"
              accept-text="Valider"
              cancel-text="Annuler"
              @close="clearFields"
              :active.sync="activePrompt"
            >
              <vs-input
                v-model="fileName"
                placeholder="Entrez le nom du fichier..."
                class="w-full"
              />
              <multiselect
                v-model="selectedFormat"
                :options="formats"
                class="my-4"
                :close-on-select="true"
                placeholder="--Choisir--"
                :selectLabel="global.selectLabel"
                :selectedLabel="global.selectedLabel"
                :deselectLabel="global.deselectLabel"
              >
                <template v-slot:noOptions>
                  <span>{{ global.selectNotFoundData }}</span>
                </template>
              </multiselect>

              <div class="flex">
                <span class="mr-4">Largeur automatique de cellule:</span>
                <vs-switch v-model="cellAutoWidth"
                  >Largeur automatique de cellule</vs-switch
                >
              </div>
            </vs-prompt>

            <div
              class="
                flex flex-wrap-reverse
                items-center
                data-list-btn-container
              "
            >
              <div>
                <div>
                  <vs-dropdown
                    vs-trigger-click
                    class="dd-actions cursor-pointer"
                  >
                    <div
                      class="
                        p-3
                        shadow-drop
                        rounded-lg
                        d-theme-dark-bg
                        cursor-pointer
                        flex
                        items-center
                        justify-center
                        text-lg
                        font-medium
                        w-32 w-full
                      "
                    >
                      <span class="mr-2">Afficher {{ limit }}</span>
                      <feather-icon
                        icon="ChevronDownIcon"
                        svgClasses="h-4 w-4"
                      />
                    </div>
                    <vs-dropdown-menu>
                      <vs-dropdown-item
                        :key="i"
                        v-for="(r, i) in rowsTable"
                        @click="limit = r"
                      >
                        <span class="flex items-center">
                          <span>{{ r }}</span>
                        </span>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
              </div>
              <div>
                <!--<vs-dropdown
                  vs-trigger-click
                  class="dd-actions cursor-pointer ml-4"
                >
                  <div
                    class="
                      p-3
                      shadow-drop
                      rounded-lg
                      d-theme-dark-bg
                      cursor-pointer
                      flex
                      items-center
                      justify-center
                      text-lg
                      font-medium
                      w-32 w-full
                    "
                  >
                    <span class="mr-2">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>
                  <vs-dropdown-menu>
                    <vs-dropdown-item @click="activePrompt = true">
                      <span class="flex items-center">
                        <feather-icon
                          icon="PrinterIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>Exporter</span>
                      </span>
                    </vs-dropdown-item>
                     <vs-dropdown-item
                      v-if="checkUserRole('ongoing_project').delete"
                      :disabled="projectSelected.length == 0"
                      @click="checkRemoveMultipleProject"
                    >
                      <span class="flex items-center">
                        <feather-icon
                          icon="Trash2Icon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>Supprimer la sélection</span>
                      </span>
                    </vs-dropdown-item>
                    <vx-tooltip
                      color="primary"
                      :title="role.notAllowTitle"
                      :text="role.notAllowText"
                      v-else
                    >
                      <vs-dropdown-item :disabled="true">
                        <span class="flex items-center">
                          <feather-icon
                            icon="Trash2Icon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                          />
                          <span>Supprimer la sélection</span>
                        </span>
                      </vs-dropdown-item>
                    </vx-tooltip> 
                  </vs-dropdown-menu>
                </vs-dropdown>-->
              </div>
            </div>
            <div class>
              <div class="w-auto" v-if="loading">
                <clip-loader :loading="loading" color="primary"></clip-loader>
              </div>
              <div v-else>
                <div v-if="allProjects.list.length !== 0">
                  <vs-table
                    multiple
                    :data="allProjects.list"
                    v-model="projectSelected"
                    search
                    stripe
                    pagination
                    :max-items="limit != 'Tout' ? limit : total"
                    noDataText="Aucun résultat trouvé"
                  >
                    <template slot="thead">
                      <vs-th>N°</vs-th>
                      <vs-th sort-key="identity">Identité</vs-th>
                      <vs-th sort-key="email">Email</vs-th>
                      <vs-th sort-key="phone">Téléphone</vs-th>
                      <vs-th sort-key="updatedAt">Dernière modification</vs-th>
                      <vs-th sort-key="ref">Ref</vs-th>
                      <vs-th sort-key="status">Etat</vs-th>
                      <vs-th sort-key="prime">Prime</vs-th>
                      <vs-th sort-key="startDateContributions"
                        >Date d'effet</vs-th
                      >
                      <vs-th>Actions</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr
                        :data="tr"
                        :key="indextr"
                        v-for="(tr, indextr) in data"
                      >
                        <vs-td :data="data[indextr].id">{{
                          indextr + 1
                        }}</vs-td>
                        <vs-td :data="data[indextr].identity">
                          {{ data[indextr].identity }}
                        </vs-td>
                        <vs-td :data="data[indextr].email">
                          {{ data[indextr].email }}
                        </vs-td>
                        <vs-td :data="data[indextr].phone">
                          {{ data[indextr].phone }}
                        </vs-td>
                        <vs-td :data="data[indextr].updatedAt">
                          {{ dateElement(data[indextr]).updatedAtInfo }}</vs-td
                        >
                        <vs-td :data="data[indextr].ref">{{
                          data[indextr].ref
                        }}</vs-td>
                        <vs-td :data="data[indextr].status">
                          <div v-if="displayStatus(data[indextr].status)">
                            {{ displayStatus(data[indextr].status) }}
                          </div>
                          <div v-else>
                            {{
                              noticeList.find(
                                (notice) => notice.id == data[indextr].notice
                              ).label
                            }}
                          </div>
                        </vs-td>
                        <vs-td :data="data[indextr].ref">{{
                          findDefaultAvenant(data[indextr].avenants)
                            .cotisationTTC
                        }}</vs-td>
                        <vs-td :data="data[indextr].startDateContributions">{{
                          formatDate(data[indextr].startDateContributions)
                        }}</vs-td>

                        <vs-td :data="data[indextr].id">
                          <div class="inline-flex" @click.stop>
                            <vs-dropdown
                              vs-trigger-click
                              class="dd-actions cursor-pointer"
                            >
                              <div class="cursor-pointer">
                                <feather-icon
                                  icon="AlignJustifyIcon"
                                  svgClasses="w-6 h-6"
                                ></feather-icon>
                              </div>
                              <vs-dropdown-menu class="aab-vie-dropdown">
                                <div>
                                  <vs-dropdown-item
                                    color="primary"
                                    v-if="
                                      data[indextr].status == 'projetNotOk' ||
                                      data[indextr].status == 'quote' ||
                                      data[indextr].status == 'notTerminate'
                                    "
                                  >
                                    <div
                                      @click="resume(data[indextr])"
                                      class="ml-1 cursor-pointer"
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          icon="ApertureIcon"
                                          class="text-primary"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3">Reprendre</span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            class="text-primary"
                                            icon="ApertureIcon"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Reprendre</span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>

                                <div>
                                  <!--<vs-dropdown-item
                                    color="primary"
                                    v-if="
                                      checkUserRole('ongoing_project').update &&
                                      data[indextr].status != 'projetNotOk' &&
                                      data[indextr].status != 'active' &&
                                      data[indextr].status != 'inactive' &&
                                      data[indextr].status != 'pendingInactive'
                                    "
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="checkClose(data[indextr])"
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          class="text-primary"
                                          icon="LockIcon"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3"
                                          >Classer sans suite</span
                                        >
                                      </span>
                                    </div>
                                  </vs-dropdown-item> -->
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            class="text-primary"
                                            icon="LockIcon"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3"
                                            >Classer sans suite</span
                                          >
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                </div>
                              </vs-dropdown-menu>
                            </vs-dropdown>

                            <vx-tooltip
                              position="left"
                              color="primary"
                              :title="aboutElement(data[indextr]).title"
                              :text="aboutElement(data[indextr]).text"
                            >
                              <div class="cursor-pointer ml-6">
                                <feather-icon
                                  icon="InfoIcon"
                                  class="text-primary"
                                  svgClasses="w-6 h-6"
                                ></feather-icon>
                              </div>
                            </vx-tooltip>

                            <div
                              class="ml-2"
                              v-show="woIsAction == data[indextr].id"
                            >
                              <clip-loader
                                :loading="
                                  loadingRemoveProject || loadingUpdateProject
                                "
                                color="primary"
                                size="25px"
                              ></clip-loader>
                            </div>
                          </div>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                  <vs-pagination
                    class="mt-6"
                    :total="allPages"
                    v-model="currentPage"
                  ></vs-pagination>
                </div>
                <div v-else>
                  <div class="flex items-center justify-center mt-8">
                    <strong>Aucun enregistrement</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mixin } from "@/mixins/mixin";
import role from "@/config/role";
import moment from "@/helpers/moment";
import global from "@/config/global";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      isResetFilter: false,
      startDate: "",
      endDate: "",
      notice: "",
      projectStatus: "",
      projetProduct: "",
      allStatus: [],
      intermediariesList: [],
      totalCountQuote: 0,
      totalCountSubscription: 10,
      popupListFiles: false,
      filesList: [],
      global: {},
      formError: false,
      formUpdated: {},
      //Pagination
      limit: 1,
      skip: 0,
      currentPage: 1,
      total: 0,
      allPages: 1,
      //Pagination
      loading: false,
      loadingRemoveProject: false,
      loadingUpdateProject: false,
      error: false,
      errorMsg: "",
      crrID: "",
      woIsAction: "",

      //export section
      fileName: "",
      formats: ["xlsx", "csv", "txt"], //formats: ["xlsx", "csv", "txt", "pdf"],
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: [
        "N°",
        "Identité",
        "Email",
        "Téléphone",
        "Dernière modification",
        "Ref",
        "Etat",
        "Prime",
        "Date d'effet",
      ],
      headerVal: [
        "index",
        "identity",
        "email",
        "phone",
        "updatedAt",
        "ref",
        "status",
        "prime",
        "startDateContributions",
      ],
      activePrompt: false,
      //end export section
      projectSelected: [],
      crrIDs: [],
    };
  },
  created() {
    // add lang in validation fields
    Validator.localize("fr", VeeValidateFR);
    this.role = role;
    this.global = global;
    // this.getIntermediarySys();
  },
  mounted() {
    if (!this.checkUserRole("ongoing_project").all)
      this.$router.push({ name: "Home" });
    this.limit = this.rowsTable[1];
    this.allStatus = this.allStatusOngoing;
  },
  computed: {
    // ...mapGetters("prospectCustomer", ["currentProject", "allProjects"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("subscribe", ["allProjects"]),
    ...mapGetters("general", [
      "rowsTable",
      "noticeList",
      "allStatusOngoing",
      "allProduct",
      "allStatusOngoing",
      "allCategorySocPro",
      "allFamilySituation",
    ]),
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("subscribe", [
      "applyGetAllProject",
      "applyGetOngoingProject",
      "applyPutOngoingProject",
      "applyRemoveOngoingProject",
      "applyRelaunchUser",
      "applyChangeProjectState",
    ]),

    ...mapActions("users", ["applyGetIntermediariesSys"]),

    findDefaultAvenant(avenants) {
      return mixin.methods.getDefaultAvenant(avenants);
    },
    displayStatus(status) {
      console.log(this.allStatus, "STATUS", status);
      //debugger
      return this.allStatus.find((el) => el.id == status)
        ? this.allStatus.find((el) => el.id == status).label
        : "";
    },
    displayProduct(product) {
      //console.log(this.allProduct,'ALLPRODUC',product)
      //console.log(this.allProduct.find(el => el.id == product))
      product = this.allProduct.find((el) => el.id == product);
      if (product) {
        return product.label;
      }
    }, 
    resume(data) {
      let link = `${
        this.global.tunnelHostUrl
      }/landing/${data.productName.toLowerCase()}?id=${data.broker.id}&type=${
        data.broker.type
      }&productID=${data.productName}&projectID=${data.id}&fromOrigin=broker`;
      window.open(link, "_blank");
    },

    // async getIntermediarySys() {
    //   let data = {
    //     limit: "Tout",
    //     skip: 0,
    //     type: [
    //       "broker_inspector",
    //       "broker_leader",
    //       "broker_merchant",
    //       "broker_general_agent",
    //       "broker"
    //     ]
    //   };
    //   this.loading = true;

    //   try {
    //     let intermediary = await this.applyGetIntermediariesSys(data);

    //     intermediary.list.forEach(el => {
    //       this.intermediariesList.push({
    //         id: el.id,
    //         fullname: el.person.firstname + el.person.lastname
    //       });
    //     });

    //     this.loading = false;
    //     this.error = false;
    //     this.errorMsg = "";
    //   } catch (err) {
    //     this.loading = false;
    //     this.error = true;
    //     this.errorMsg = "";

    //     if (err.response) {
    //       if (
    //         err.response.status &&
    //         err.response.statusText === "Unauthorized"
    //       ) {
    //         this.acceptAlert(
    //           "danger",
    //           "Authentification",
    //           "Votre session a expirée"
    //         );
    //         await this.logout();
    //         this.$router.push({ name: "Login" });
    //       } else {
    //         err.response.data.errors.forEach(item => {
    //           switch (item.code) {
    //             case "userNotFound":
    //               this.errorMsg = "Votre nom d'utilisateur n'existe pas";
    //               this.acceptAlert("danger", "Utilisateurs", this.errorMsg);
    //               break;

    //             default:
    //               this.acceptAlert(
    //                 "danger",
    //                 "Utilisateurs",
    //                 "Une erreur s'est produite"
    //               );
    //               break;
    //           }
    //         });
    //       }
    //     }
    //   }
    // },
    resetFilter() {
      this.isResetFilter = true;
      this.notice = "";
      this.startDate = "";
      this.endDate = "";
      this.projetProduct = "";
      this.projectStatus = "";
      this.isResetFilter = false;

      this.fetchApplyGetAllProject({
        limit: this.limit,
        skip: this.skip,
        startDate: this.startDate,
        endDate: this.endDate,
        isProjectPending: true,
        productName: this.projetProduct.id ? this.projetProduct.id : "",
        notice: this.notice.id
          ? this.notice.id
          : [
              "toStudy",
              "fullInfo",
              "medicalVisit",
              "rejection",
              "standardValidation",
              "validationWithExtraPremium",
            ],
        status: this.projectStatus.id
          ? this.projectStatus.id
          : ["quote", "notTerminate", "projetNotOk", "none"],
        broker: this.user.id,
      });
    },
    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },
    lastLogin(data) {
      return mixin.methods.lastLogin(data.lastLoginAt);
    },
    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },
    formatDate(data) {
      return mixin.methods.formatDate(data);
    },

    checkClose(item) {
      this.crrID = item.id;
      this.formUpdated = { ...item };

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment fermer ce projet en cours ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.closeProject,
      });
    },

    async closeProject() {
      this.loadingUpdateProject = true;
      this.woIsAction = this.crrID;

      let data = {
        ...this.formUpdated,
        projectId: this.crrID,
        status: "projetNotOk",
      };
      console.log("stat:", data.status);
      try {
        await this.applyChangeProjectState(data);
        this.acceptAlert(
          mixin.methods.message("ongoing_project", "close").success
        );
        this.formUpdated = {};
        this.woIsAction = "";
        this.loadingUpdateProject = false;
      } catch (err) {
        this.woIsAction = "";
        this.loadingUpdateProject = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Mise à jour", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("ongoing_project", "close").error
                  );
                  break;
              }
            });
          }
        }
      }
    },

    checkViewFile(item) {
      // window.open(this.showFileData(item.filename), "_blank");
      window.open(
        this.showFileData("460a45ad-2166-4009-866d-dd3fb9ced36a.PNG"),
        "_blank"
      );
    },
    showFileData(data) {
      return `${this.$config.serverURL}/downloads/${data}`;
    },
    // exportToData() { 
    //   if (this.selectedFormat == "pdf") {
    //     let tableToPrintPdf = [];

    //     this.allProjects.list.forEach((el, index) => {
    //       let jsonTmp = {
    //         identity: el.identity,
    //         email: el.email,
    //         phone: el.phone,
    //         updatedAt: dateElement(el).updatedAtInfo,
    //         ref: el.ref,
    //         status: displayStatus(el.status),
    //         prime: el.ref,
    //         startDateContributions: formatDate(el.startDateContributions),
    //       };
    //       tableToPrintPdf.push(jsonTmp);
    //     });

    //     let headerArray = [];

    //     this.headerTitle.forEach((title, index) => {
    //       headerArray.push({ header: title, dataKey: this.headerVal[index] });
    //     });
    //     mixin.methods.exportToPdf(
    //       [...tableToPrintPdf],
    //       headerArray,
    //       this.fileName,
    //       "LES INTERMEDIAIRES"
    //     );
    //   } else {
    //     let tableToPrint = [];
    //     this.allProjects.list.forEach((el) => {
    //       let jsonTmp = {
    //         identity: el.identity,
    //         email: el.email,
    //         phone: el.phone,
    //         updatedAt: dateElement(el).updatedAtInfo,
    //         ref: el.ref,
    //         status: displayStatus(el.status),
    //         prime: el.ref,
    //         startDateContributions: formatDate(el.startDateContributions),
    //       };
    //       tableToPrint.push(jsonTmp);
    //     });
    //     let headerTitleTmp = [
    //       "Identité",
    //       "Email",
    //       "Téléphone",
    //       "Dernière modification",
    //       "Ref",
    //       "Etat",
    //       "Prime",
    //       "Date d'effet",
    //     ];

    //     let headerValTmp = [
    //       "identity",
    //       "email",
    //       "phone",
    //       "updatedAt",
    //       "ref",
    //       "status",
    //       "prime",
    //       "startDateContributions",
    //     ];

    //     mixin.methods.exportToData(
    //       tableToPrint,
    //       headerTitleTmp,
    //       headerValTmp,
    //       this.fileName,
    //       this.cellAutoWidth,
    //       this.selectedFormat
    //     );
    //   }
    //   this.clearFields();
    // },
    exportToData() {
      if (this.selectedFormat == "pdf") {
        let tableToPrintPdf = [];

        this.allProjects.list.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            type: this.profilList.find((type) => type.id == el.type).label,
            firstname: el.person.firstname,
            lastname: el.person.lastname,
            username: el.username,
            email: el.email,
          };
          tableToPrintPdf.push(jsonTmp);
        });

        let headerArray = [];

        this.headerTitle.forEach((title, index) => {
          headerArray.push({ header: title, dataKey: this.headerVal[index] });
        });
        mixin.methods.exportToPdf(
          [...tableToPrintPdf],
          headerArray,
          this.fileName,
          "LES INTERMEDIAIRES"
        );
      } else {
        let tableToPrint = [];
        // this.allProspectClients.list.forEach(el => {
        //   let jsonTmp = {
        //     type: this.profilList.find(type => type.id == el.type).label,
        //     lastname: el.person.lastname,
        //     firstname: el.person.firstname,
        //     username: el.username,
        //     email: el.email,
        //     profession: el.profession,
        //     status: el.status
        //   };
        //   tableToPrint.push(jsonTmp);
        // });

        // let headerValTmp = ["type", "firstname", "lastname", "email", "status"];

        // let headerTitleTmp = ["Profil", "Prénom(s)", "Nom", "Email", "Statut"];

        this.allProjects.list.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            prospect_sexe: el.quote.civility,
            prospect_lastname: el.quote.lastname,
            prospect_firstname: el.quote.firstname,
            prospect_birthday: this.dateElement({ birthday: el.quote.birthday })
              .birthdayInfo,
            prospect_phone: el.quote.phone,
            prospect_startDateContributions: this.dateElement({
              birthday: el.quote.startDateContributions,
            }).birthdayInfo,
            prospect_email: el.quote.email,

            // profil_broker: this.profilList.find((type) => type.id == el.broker.type)
            //   ? this.profilList.find((type) => type.id == el.broker.type).label
            //   : "",
            // type_broker: this.brokerTypeList.find(
            //   (type) => type.id == el.personality
            // )
            //   ? this.brokerTypeList.find((type) => type.id == el.personality)
            //       .label
            //   : "",
            // userCode_broker: el.broker.userCode,
            // lastname_broker: el.broker.person.lastname,
            // firstname_broker: el.broker.person.firstname,
            // forSociety_broker: el.broker.person.forSociety,
            // addres_broker: el.broker.person.addres,
            // phone_broker: el.broker.person.phone,
            username_broker: `${el.broker.person.firstname} ${el.broker.person.lastname}`,
            // email_broker: el.broker.email,
            // logo_broker: this.showFileData(el.person.logo),

            // assure
            ...this.onGetAssured(el.subscription.assured),
            // subscriber
            ...this.onGetSubscriber(el.subscription.subscriber),
            // beneficiariesDeceased
            ...this.onGetBeneficiariesDeceased(
              el.subscription.beneficiariesDeceased
            ),
            // survey
            ...this.onGetSurvey(el.subscription.survey),
            ...this.onGetTarif(el.productName, el.quote),
            ref: el.ref,
            status: this.displayStatus(el.status),
          };
          tableToPrint.push(jsonTmp);
        });

        let headerValTmp = [
          "status",
          "ref",

          // "profil_broker",
          // "type_broker",
          // "firstname_broker",
          // "lastname_broker",
          "username_broker",
          // "userCode_broker",
          // "email_broker",
          // "forSociety_broker",
          // "phone_broker",
          // "addres_broker",
          // "logo_broker",

          "prospect_sexe",
          "prospect_lastname",
          "prospect_firstname",
          "prospect_birthday",
          "prospect_phone",
          "prospect_email",
          "prospect_startDateContributions",

          "amountOfCapital",
          "corverageDuration",
          "capitalDeathAllCauses",
          "absoluteDefinitiveDisabilityCapital",
          "doublingDeathBenefit",
          "partialPermanentDisability",
          "accumulationContributions",
          "ahr_amountOfCapital",
          "ahr_contributionPeriod",
          "ahr_corverageDuration",
          "ahr_startDateContributions",
          "ahr_endDateContributions",
          "ahr_numberOfContributions",
          "ahr_accumulationOfContributions",
          "ahr_accumulationContributions",

          "subscriber_clientNumber",
          "subscriber_sexe",
          "subscriber_lastname",
          "subscriber_firstname",
          "subscriber_email",
          "subscriber_birthday",
          "subscriber_phone",
          "subscriber_commune",
          "subscriber_adress",
          "subscriber_categorySocioProfessional",
          "subscriber_familySituation",
          "subscriber_profession",
          "subscriber_identity",

          "assured_sexe",
          "assured_lastname",
          "assured_firstname",
          "assured_email",
          "assured_birthday",
          "assured_phone",
          "assured_commune",
          "assured_adress",
          "assured_categorySocioProfessional",
          "assured_familySituation",
          "assured_profession",
          "assured_identity",
          "BeneficiariesDeceased_name",
          "survey",
        ];
        // // tarif
        // "amountOfCapital", "corverageDuration","amountOfCapital", "amountOfCapital",
        // "doublingDeathBenefit","partialPermanentDisability",
        // assure
        //tarif
        // // ABQ
        // "Montant du capital", "Durée de couverture", "Capital Décès Toutes Causes",
        // "Capital Invalidité Absolue et Définitive", "Doublement du capital décès",
        // "Invalidité Partielle et Permanente",
        // // AHR
        // "Montant du capital à terme", "Périodicité des cotisations", "Durée des cotisations (en année)","Date de début des cotisations","Date de fin des cotisations","Nombre de cotisations","Cumul des cotisations mensuelles",
        // //AAE
        // "Rente annuelle à servir sur 5 ans","Décès ou Invalidité Absolue et Définitive","Périodicité des cotisations",
        // "Durée des cotisations","Date de début des cotisations","Date de fin des cotisations", "Nombre de cotisations", "Cumul des cotisations",
        //Souscripteur
        let headerTitleTmp = [
          "Etat",
          "Référence",

          // "Profil courtier",
          // "Type courtier",
          // "Prénom(s) courtier",
          // "Nom courtier",
          "Informations courtier",
          // "Code apporteur courtier",
          // "Email courtier",
          // "Société courtier",
          // "Téléphone courtier",
          // "Adresse courtier",
          // "Logo courtier",

          "Civilité prospect",
          "Nom prospect",
          "Prénoms prospect",
          "Date de naissance prospect",
          "Téléphone prospect",
          "Email prospect",
          "Date d’effet souhaitée prospect",

          "Montant du capital",
          "Durée de couverture",
          "Capital Décès Toutes Causes",
          "Capital Invalidité Absolue et Définitive",
          "Doublement du capital décès",
          "Invalidité Partielle et Permanente",
          "Cotisation",

          "Montant du capital à terme",
          "Périodicité des cotisations",
          "Durée des cotisations (en année)",
          "Date de début des cotisations",
          "Date de fin des cotisations",
          "Nombre de cotisations",
          "Cumul des cotisations mensuelles",
          "Cotisation mensuelle",

          "Numéro souscripteur",
          "Civilité souscripteur",
          "Nom souscripteur",
          "Prénoms souscripteur",
          "Email souscripteur",
          "Date de naissance souscripteur",
          "Téléphone souscripteur",
          "Commune de naissance souscripteur",
          "Adresse de résidence souscripteur",
          "Catégorie socioprofessionnelle souscripteur",
          "Situation familiale souscripteur",
          "Profession souscripteur",
          "Pièce d’identité souscripteur",

          "Civilité assuré",
          "Nom assuré",
          "Prénoms assuré",
          "Email assuré",
          "Date de naissance assuré",
          "Téléphone assuré",
          "Commune de naissance assuré",
          "Adresse de résidence assuré",
          "Catégorie socioprofessionnelle assuré",
          "Situation familiale assuré",
          "Profession assuré",
          "Pièce d’identité assuré",

          "Nom bénéficiaire ",
          "Questionnaire médical",
        ];
        mixin.methods.exportToData(
          tableToPrint,
          headerTitleTmp,
          headerValTmp,
          this.fileName,
          this.cellAutoWidth,
          this.selectedFormat
        );
      }
      this.clearFields();
    },
    onGetAssured(data) {
      if (data) {
        return {
          assured_sexe: data.civility,
          assured_lastname: data.lastname,
          assured_firstname: data.firstname,
          assured_email: data.email,
          assured_birthday: this.dateElement({ birthday: data.birthday })
            .birthdayInfo,
          assured_phone: data.phone,
          assured_commune: data.commune,
          assured_adress: data.adress,
          assured_categorySocioProfessional: this.allCategorySocPro.find(
            (el) => el.id == data.categorySocioProfessional
          )
            ? this.allCategorySocPro.find(
                (el) => el.id == data.categorySocioProfessional
              ).label
            : "",
          assured_familySituation: this.allFamilySituation.find(
            (el) => el.id == data.familySituation
          )
            ? this.allFamilySituation.find((el) => el.id == data.familySituation)
                .label
            : "",
          assured_profession: data.profession,
          assured_identity: data.identity ? data.identity.numIdentity : "",
        };
      } else {
        return {
          assured_sexe: "",
          assured_lastname: "",
          assured_firstname: "",
          assured_email: "",
          assured_birthday: "",
          assured_phone: "",
          assured_commune: "",
          assured_adress: "",
          assured_categorySocioProfessional: "",
          assured_familySituation: "",
          assured_profession: "",
          assured_identity: "",
        };
      }
    },
    onGetSubscriber(data) {
      console.log("data", data);

      if (data) {
        return {
          subscriber_sexe: data.civility,
          subscriber_clientNumber: data.clientNumber,
          subscriber_lastname: data.lastname,
          subscriber_firstname: data.firstname,
          subscriber_email: data.email,
          subscriber_birthday: this.dateElement({ birthday: data.birthday })
            .birthdayInfo,
          subscriber_phone: data.phone,
          subscriber_commune: data.commune,
          subscriber_adress: data.adress,
          subscriber_categorySocioProfessional: this.allCategorySocPro.find(
            (el) => el.id == data.categorySocioProfessional
          )
            ? this.allCategorySocPro.find(
                (el) => el.id == data.categorySocioProfessional
              ).label
            : "",
          subscriber_familySituation: this.allFamilySituation.find(
            (el) => el.id == data.familySituation
          )
            ? this.allFamilySituation.find((el) => el.id == data.familySituation)
                .label
            : "",
          subscriber_profession: data.profession,
          subscriber_identity: data.identity ? data.identity.numIdentity : "",
        };
      } else {
        return {
          subscriber_sexe: "",
          subscriber_lastname: "",
          subscriber_firstname: "",
          subscriber_email: "",
          subscriber_birthday: "",
          subscriber_phone: "",
          subscriber_commune: "",
          subscriber_adress: "",
          subscriber_categorySocioProfessional: "",
          subscriber_familySituation: "",
          subscriber_profession: "",
          subscriber_identity: "",
        };
      }
    },
    onGetBeneficiariesDeceased(data) {
      if (data) {
        return {
          BeneficiariesDeceased_name:
            data.name == "myPartner" ? "Mon conjoint" : `${data.value}`,
        };
      } else {
        return {
          BeneficiariesDeceased_name: "",
        };
      }
    },
    onGetSurvey(data) {
      if (data) {
        let surveyData = [];
        data.forEach((res) => {
          if (res.response.text) {
            surveyData.push(
              `${res.question}: ${res.response.text.value} ${res.response.text.placeholder} `
            );
          } else {
            surveyData.push(
              `${res.question}: ${res.response.quickResponse ? "OUI" : "NON"}`
            );
          }
        });
        return { survey: surveyData.join("; ") };
      } else {
        return { survey: "" };
      }
    },
    onGetTarif(productName, data) {
      console.log(productName, data, "(productName, data) ");
      if (productName == "ABQ" || productName == "APF" || productName == "AAF") {
        if (data.aboutProposition) {
          const propositionChoose = data.aboutProposition.data.find(
            (el) => el.id == data.aboutProposition.selectedId
          );
          return {
            amountOfCapital: propositionChoose.amountOfCapital,
            capitalDeathAllCauses: propositionChoose.capitalDeathAllCauses,
            accumulationContributions:
              propositionChoose.accumulationContributions,
            absoluteDefinitiveDisabilityCapital:
              propositionChoose.absoluteDefinitiveDisabilityCapital,
            corverageDuration: propositionChoose.corverageDuration,
            doublingDeathBenefit:
              propositionChoose.doublingDeathBenefit == true ? "Oui" : "Non",
            partialPermanentDisability:
              propositionChoose.partialPermanentDisability == true
                ? "Oui"
                : "Non",
          };
        } else {
          return {
            amountOfCapital: "",
            capitalDeathAllCauses: "",
            accumulationContributions: "",
            absoluteDefinitiveDisabilityCapital: "",
            corverageDuration: "",
            doublingDeathBenefit: "",
            partialPermanentDisability: "",
          };
        }
      } else if (productName == "AHR") {
        if (data.aboutProposition) {
          const propositionChoose = data.aboutProposition.data.find(
            (el) => el.id == data.aboutProposition.selectedId
          );
          return {
            ahr_amountOfCapital: propositionChoose.amountOfCapital,
            ahr_contributionPeriod: "Mensuelle",
            ahr_corverageDuration: propositionChoose.corverageDuration,
            ahr_startDateContributions: this.dateElement({
              birthday: data.startDateContributions,
            }).birthdayInfo,
            ahr_endDateContributions: this.dateElement({
              birthday: propositionChoose.endDateContributions,
            }).birthdayInfo,
            ahr_numberOfContributions: propositionChoose.numberOfContributions,
            ahr_accumulationOfContributions:
              propositionChoose.accumulationOfContributions,
            ahr_accumulationContributions:
              propositionChoose.accumulationContributions,
          };
        } else {
          return {
            ahr_amountOfCapital: "",
            ahr_contributionPeriod: "",
            ahr_corverageDuration: "",
            ahr_startDateContributions: "",
            ahr_endDateContributions: "",
            ahr_numberOfContributions: "",
            ahr_accumulationOfContributions: "",
            ahr_accumulationContributions: "",
          };
        }
      }
    },
    clearFields() {
      (this.filename = ""),
      (this.fileName = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },
    dateElement(data) {
      return mixin.methods.dateElement(data);
    },
    async fetchApplyGetAllProject(data) {
      this.loading = true;

      try {
        await this.applyGetAllProject(data);
        console.log(this.allProjects, "------>>>>>");

        this.total = this.allProjects.total;
        let p = Math.ceil(this.total / this.limit);
        this.allPages = p ? p : 1;

        this.totalCountSubscription = this.allProjects.list.filter(
          (el) => el.status == "projetNotOk"
        ).length;
        this.totalCountQuote = this.allProjects.total;
        this.loading = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        console.log("bbbb", err);
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Utilisateurs", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Utilisateurs",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },

    async fetchApplyUpdateProject() {
      this.loadingUpdateProject = true;

      let data = {};
      let Ids = [];
      this.formUpdated.intermediaries.forEach((el) => {
        Ids.push(el.id);
      });
      data = {
        userId: this.crrID,
        ...this.formUpdated,
        logo: this.fileData.data ? this.fileData.data[0].uploadedName : "",
        type: this.formUpdated.type.id,
        intermediaries: Ids,
      };

      try {
        await this.applyPutOngoingProject(data);

        this.acceptAlert(
          "success",
          "Mise à jour",
          "Intermediaire a été mise à jour"
        );
        this.formUpdated = {
          email: "",
          firstname: "",
          lastname: "",
          type: "",
          intermediaries: [],
        };
        this.loadingUpdateProject = false;
      } catch (err) {
        this.loadingUpdateProject = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Mise à jour", this.errorMsg);
                  break;
                case "sendMailError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Mise à jour", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Mise à jour",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },

    checkRemoveMultipleProject() {
      this.projectSelected.forEach((element) => {
        this.crrIDs.push(element.id);
      });
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment supprimer les projets en cours sélectionnés ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchRemoveMultipleProject,
      });
    },

    async fetchRemoveMultipleProject() {
      let data = {
        ids: this.crrIDs,
      };

      this.loading = true;
      try {
        await this.applyRemoveOngoingProject(data);
        this.loading = false;
        this.acceptAlert(
          "success",
          "Suppression",
          "Les projets en cours sélectionnés ont été supprimés avec succès"
        );
        this.crrIDs = [];
        this.projectSelected = [];
      } catch (err) {
        this.loading = false;
        this.crrIDs = [];
        this.projectSelected = [];

        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                default:
                  this.acceptAlert(
                    "danger",
                    "Suppression",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    checkRemove(project) {
      this.crrID = project.id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment supprimer ${project.person.lastname} ${project.person.firstname} ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyRemoveProject,
      });
    },
    async fetchApplyRemoveProject() {
      this.woIsAction = this.crrID;
      let data = {
        ids: [this.crrID],
      };
      this.loadingRemoveProject = true;
      try {
        await this.applyRemoveOngoingProject(data);
        this.loadingRemoveProject = false;
        this.acceptAlert(
          "success",
          "Suppression",
          "Projet a été supprimé avec succès"
        );
        this.woIsAction = "";
        this.projectSelected = [];
      } catch (err) {
        this.loadingRemoveProject = false;
        this.woIsAction = "";
        this.projectSelected = [];
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert(
                    "danger",
                    "Suppression",
                    "Utilisateur n'existe pas"
                  );
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Suppression",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    checkRelaunch(item) {
      this.crrID = item.id;
      this.formUpdated = { ...item };
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment relancer  ${item.person.lastname} ${item.person.firstname} ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyRelaunchProject,
      });
    },
    async fetchApplyRelaunchProject() {
      this.woIsAction = this.crrID;
      let data = {
        projectId: this.crrID,
        ...this.formUpdated,
      };

      this.loadingUpdateProject = true;
      try {
        await this.applyRelaunchUser(data);
        this.loadingUpdateProject = false;
        this.acceptAlert("success", "Relance", "Relance envoyé avec succès");
        this.formUpdated = {};
      } catch (err) {
        this.loadingUpdateProject = false;
        this.woIsAction = "";
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert(
                    "danger",
                    "Mot de passe",
                    "Administrateur n'existe pas"
                  );
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Mot de passe",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    /*
    ongoingProjectsSelectedDouble(item) {
      if (this.checkUserRole("ongoing_project").show) {
        this.checkViewFile(item);
      } else {
        this.acceptAlert(
          "danger",
          this.role.notAllowTitle,
          this.role.notAllowText
        );
      }
    }*/
  },
  watch: {
    projectStatus() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          startDate: this.startDate,
          endDate: this.endDate,
          isProjectPending: true,
          productName:
            this.projetProduct && this.projetProduct.id
              ? this.projetProduct.id
              : "",
          notice: this.notice.id
            ? this.notice.id
            : [
                "toStudy",
                "fullInfo",
                "medicalVisit",
                "rejection",
                "standardValidation",
                "validationWithExtraPremium",
              ],
          status:
            this.projectStatus && this.projectStatus.id
              ? this.projectStatus.id
              : ["quote", "notTerminate", "projetNotOk", "none"],
          broker: this.user.id,
        });
      }
    },

    notice() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          startDate: this.startDate,
          endDate: this.endDate,
          isProjectPending: true,
          productName:
            this.projetProduct && this.projetProduct.id
              ? this.projetProduct.id
              : "",
          notice: this.notice.id
            ? this.notice.id
            : [
                "toStudy",
                "fullInfo",
                "medicalVisit",
                "rejection",
                "standardValidation",
                "validationWithExtraPremium",
              ],
          status:
            this.projectStatus && this.projectStatus.id
              ? this.projectStatus.id
              : ["quote", "notTerminate", "projetNotOk", "none"],
          broker: this.user.id,
        });
      }
    },

    projetProduct() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          startDate: this.startDate,
          endDate: this.endDate,
          isProjectPending: true,
          productName:
            this.projetProduct && this.projetProduct.id
              ? this.projetProduct.id
              : "",
          notice: this.notice.id
            ? this.notice.id
            : [
                "toStudy",
                "fullInfo",
                "medicalVisit",
                "rejection",
                "standardValidation",
                "validationWithExtraPremium",
              ],
          status:
            this.projectStatus && this.projectStatus.id
              ? this.projectStatus.id
              : ["quote", "notTerminate", "projetNotOk", "none"],
          broker: this.user.id,
        });
      }
    },
    endDate() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          startDate: this.startDate,
          endDate: this.endDate,
          isProjectPending: true,
          productName:
            this.projetProduct && this.projetProduct.id
              ? this.projetProduct.id
              : "",
          notice: this.notice.id
            ? this.notice.id
            : [
                "toStudy",
                "fullInfo",
                "medicalVisit",
                "rejection",
                "standardValidation",
                "validationWithExtraPremium",
              ],
          status:
            this.projectStatus && this.projectStatus.id
              ? this.projectStatus.id
              : ["quote", "notTerminate", "projetNotOk", "none"],
          broker: this.user.id,
        });
      }
    },
    startDate() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          startDate: this.startDate,
          endDate: this.endDate,
          isProjectPending: true,
          productName:
            this.projetProduct && this.projetProduct.id
              ? this.projetProduct.id
              : "",
          notice: this.notice.id
            ? this.notice.id
            : [
                "toStudy",
                "fullInfo",
                "medicalVisit",
                "rejection",
                "standardValidation",
                "validationWithExtraPremium",
              ],
          status:
            this.projectStatus && this.projectStatus.id
              ? this.projectStatus.id
              : ["quote", "notTerminate", "projetNotOk", "none"],
          broker: this.user.id,
        });
      }
    },
    currentPage() {
      this.skip = (this.currentPage - 1) * this.limit;

      this.fetchApplyGetAllProject({
        limit: this.limit,
        skip: this.skip,
        startDate: this.startDate,
        endDate: this.endDate,
        isProjectPending: true,
        productName:
          this.projetProduct && this.projetProduct.id
            ? this.projetProduct.id
            : "",
        notice: this.notice.id
          ? this.notice.id
          : [
              "toStudy",
              "fullInfo",
              "medicalVisit",
              "rejection",
              "standardValidation",
              "validationWithExtraPremium",
            ],
        status:
          this.projectStatus && this.projectStatus.id
            ? this.projectStatus.id
            : ["quote", "notTerminate", "projetNotOk", "none"],
        broker: this.user.id,
      });
    },
    limit() {
      this.skip = 0;

      this.fetchApplyGetAllProject({
        limit: this.limit,
        skip: this.skip,
        startDate: this.startDate,
        endDate: this.endDate,
        isProjectPending: true,
        productName:
          this.projetProduct && this.projetProduct.id
            ? this.projetProduct.id
            : "",
        notice: this.notice.id
          ? this.notice.id
          : [
              "toStudy",
              "fullInfo",
              "medicalVisit",
              "rejection",
              "standardValidation",
              "validationWithExtraPremium",
            ],
        status:
          this.projectStatus && this.projectStatus.id
            ? this.projectStatus.id
            : ["quote", "notTerminate", "projetNotOk", "none"],
        broker: this.user.id,
      });
    },
  },
  components: {
    ClipLoader,
    Multiselect,
  },
};
</script>

<style lang="scss">
.aab-vie-icon-clickable {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}

.aab-vie-input-container {
  border: 2px solid #a0a0a0;
  text-align: center;
  padding: 10px;
  padding-bottom: 10px;
}
</style>
